import type { DecoratedUser } from "@/types";

export const useUser = () => {
  const { auth } = useSupabaseClient();
  const decoratedUserCookie = useCookie('decoratedUser');

  const decoratedUser = useState(
    "appDecoratedUser",
    (): DecoratedUser | null => decoratedUserCookie
  );
  const user = useSupabaseUser();

  const { auth: authConfig } = useAppConfig();

  async function register({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<{ error: Error | null }> {
    let error = null;
    try {
      await $fetch("/api/auth/register", {
        method: "POST",
        body: { email, password },
      });
      if (authConfig.confirmEmail) {
        navigateTo(`/auth/confirm-email?email=${email}`);
      } else {
        navigateTo(authConfig.redirectAfterLogin);
      }
    } catch (err) {
      // @ts-expect-error
      error = createError(err.data.message || "Error registering user");
    }

    return { error };
  }

  async function fetchMeta() {
    if (!user.value) return;
    try{
      const data = await $fetch("/api/me");
      decoratedUser.value = data;
    }catch(err){
      decoratedUser.value = null;
    }
  }

  async function init() {
    async function setDecorated() {
      if (user.value && !decoratedUser.value) {
        await fetchMeta();
      }
      if (!user.value) {
        decoratedUser.value = null;
      }
    }
    await setDecorated();
    watch(user, setDecorated);
  }
  async function logout(redirectTo:string = "/auth/login") {
    await auth.signOut();
    await navigateTo(redirectTo, { replace: true });
    decoratedUserCookie.value = null;
    decoratedUser.value = null;
  }

  return {
    user: decoratedUser,
    fetchMeta,
    register,
    init,
    logout
  };
};
